import.meta.glob([
    '../img/**',
    '../fonts/**',
]);

import * as Sentry from "@sentry/browser";

import 'zoomist/css'
// import Zoomist
import Zoomist from 'zoomist'
window.Zoomist = Zoomist;
import {Alpine, Livewire} from '../../vendor/livewire/livewire/dist/livewire.esm';

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
});
Alpine.data('recaptchaButton', (element, key) => {
    return {
        execute(e) {
            const clickElement = this.$event.target
            if (clickElement.hasAttribute('disabled')) {
                return;
            }
            clickElement.classList.add('disabled');
            clickElement.setAttribute('disabled', 'disabled');
            console.log('execute');
            try {
                grecaptcha.execute(key, {action: 'recaptcha'})
                    .then(function (token) {
                        console.log('execute then');
                        element.set('recaptcha', token)
                        element.submit()
                    })
            } catch (e) {
                console.error(e);
            } finally {
                clickElement.classList.remove('disabled')
                clickElement.removeAttribute('disabled')
            }


        }
    }
})
window.initializeZoomScript = function () {
    let currentClick;
    let currentTarget;

    new Zoomist('.zoomist-container', {
        // Optional parameters
        bounds: false,
        // if you need slider
        slider: true,
        draggable: true,
        // if you need zoomer
        zoomer: true,
        minScale: 0.1,
        initScale: 1,
        on: {
            dragStart(zoomist, scale, event) {
                currentClick = null;
                currentTarget = null;
                if (event.target.tagName === 'A') {
                    currentClick = scale;
                    currentTarget = event.target;
                }
            },
            dragEnd(zoomist, scale, event) {
                if (!currentClick) {
                    return;
                }
                if (scale.x === currentClick.x && scale.y === currentClick.y && currentTarget) {
                    currentTarget.click()
                }
            }
        },
    });
}

window.createRecaptchaScript = (url) => {
    console.log('createRecaptchaScript');
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.onload = function () {
        // remote script has loaded
    };
    script.src = url;
    document.getElementsByTagName('head')[0].appendChild(script);
}

window.preventNegative = (div) => {
    const input = div.querySelector('input');
    if (input) {
        let value = input.value;
        if (value.includes('-')) {
            input.value = value.replace('-', '');
        }
    }
}

window.sortTable = () => {
    return {
        sortBy: "",
        sortAsc: false,
        sortByColumn($event) {
            if (this.sortBy === $event.target.innerText) {
                if (this.sortAsc) {
                    this.sortBy = "";
                    this.sortAsc = false;
                } else {
                    this.sortAsc = !this.sortAsc;
                }
            } else {
                this.sortBy = $event.target.innerText;
            }

            let rows = this.getTableRows()
                .sort(
                    this.sortCallback(
                        Array.from($event.target.parentNode.children).indexOf(
                            $event.target
                        )
                    )
                )
                .forEach((tr) => {
                    this.$refs.tbody.appendChild(tr);
                });
        },
        getTableRows() {
            return Array.from(this.$refs.tbody.querySelectorAll("tr"));
        },
        getCellValue(row, index) {
            return row.children[index].innerText;
        },
        sortCallback(index) {
            return (a, b) =>
                ((row1, row2) => {
                    return row1 !== "" &&
                    row2 !== "" &&
                    !isNaN(row1) &&
                    !isNaN(row2)
                        ? row1 - row2
                        : row1.toString().localeCompare(row2);
                })(
                    this.getCellValue(this.sortAsc ? a : b, index),
                    this.getCellValue(this.sortAsc ? b : a, index)
                );
        }
    };
}


Livewire.start()
